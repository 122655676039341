@charset 'utf-8';

@import 'settings';
@import 'foundation';
@import 'motion-ui';


// Global styles
@include foundation-global-styles;
@include foundation-forms;
@include foundation-typography;

// Grids (choose one)
@include foundation-xy-grid-classes;
// @include foundation-grid;
// @include foundation-flex-grid;

// Generic components
@include foundation-button;
@include foundation-button-group;
@include foundation-close-button;
@include foundation-label;
@include foundation-progress-bar;
@include foundation-slider;
@include foundation-range-input;
@include foundation-switch;
@include foundation-table;
// Basic components
@include foundation-badge;
@include foundation-breadcrumbs;
@include foundation-callout;
@include foundation-card;
@include foundation-dropdown;
@include foundation-pagination;
@include foundation-tooltip;

// Containers
@include foundation-accordion;
@include foundation-media-object;
@include foundation-orbit;
@include foundation-responsive-embed;
@include foundation-tabs;
@include foundation-thumbnail;
// Menu-based containers
@include foundation-menu;
@include foundation-menu-icon;
@include foundation-accordion-menu;
@include foundation-drilldown-menu;
@include foundation-dropdown-menu;

// Layout components
@include foundation-off-canvas;
@include foundation-reveal;
@include foundation-sticky;
@include foundation-title-bar;
@include foundation-top-bar;

// Helpers
@include foundation-float-classes;
@include foundation-flex-classes;
@include foundation-visibility-classes;
// @include foundation-prototype-classes;


// Motion UI
@include motion-ui-transitions;
@include motion-ui-animations;


//Keen Slider
@import "node_modules/keen-slider/keen-slider.scss";
@import "node_modules/quill/dist/quill.core";
@import "node_modules/quill/dist/quill.snow";


html, body {
  overflow-x: hidden;
  width: 100%;
}

$primeColor: #85bfba;
$darkColor:#356c6b;
$headerColor:#253b3c;
$lightColor-100: #daedea;
$lightColor-50:#f4f9f8;
$darkColor-900:#112122;
$navBarColor: #f8f8f8;
$darkColor-700:#2e5757;

$breakpoints: (
        small: 0px,
        medium: 640px,
        large: 1024px,
        xlarge: 1200px,
        xxlarge: 1400px,
        megalarge:1510px,
        gigalarge:1520px
);

//---------------------------------------------------SECTION------------------------------------------------------------
section{
  padding-top: 3rem;
  padding-bottom: 10rem;
}

.section-landing-page{
  padding-top: 3rem;
  padding-bottom: 4rem;
}

.blue-curve-left{
  background-color: #f9f9f9;
  background-image: url("https://id-ni.eco/assets/images/svg/curve-bottom.svg");
  background-position: top left;
  background-repeat: no-repeat;
  background-size: 30rem;
  h2{
    padding-left: 2rem;
    color:#f9f9f9;
    font-size: 2rem;
    width:10px;
  }
  .h-padding{
    font-size:1.67rem;
    padding-left: 1rem;
  }
}

.blue-curve-right{
  background-image: url("https://id-ni.eco/assets/images/svg/curve-bottom-right.svg");
  background-position: top right;
  background-repeat: no-repeat;
  background-size: 30rem;
  h2{
    text-align: end;
    color:#ffffff;
    font-size: 2rem;
    padding-right: 3.5rem;
  }
}

.blue-curve-right-bck{
  background-color: #f9f9f9;
  background-image: url("https://id-ni.eco/assets/images/svg/curve-bottom-right.svg");
  background-position: top right;
  background-repeat: no-repeat;
  background-size: 30rem;
  h2{
    text-align: end;
    color:#ffffff;
    font-size: 2rem;
    padding-right: 3.5rem;
  }
}

.blue-curve-left-longtitle{
  background-color: #f9f9f9;
  background-image: url("https://id-ni.eco/assets/images/svg/curve-bottom.svg");
  background-position: top left;
  background-repeat: no-repeat;
  padding:30px;
  background-size: 30rem;
  position: relative;
  h2{
    color:#f9f9f9;
    font-size: 1.8rem;
    //width:10px;
    position: absolute;
    bottom:0;
    top:25px;
    left:20px;
    right:0;
  }
}



.pet-card{
  background: #ffffff;
  color: #4b5563;
  box-shadow: 0 2px 1px -1px rgba(0, 0, 0, 0.2), 0 1px 1px 0 rgba(0, 0, 0, 0.14), 0 1px 3px 0 rgba(0, 0, 0, 0.12);
  border-radius: 6px;
  padding: 2.25rem;
  .title{
    padding-bottom: 10px;
  }
}

.pet-section{
  background-image: url("https://id-ni.eco/assets/images/svg/curve-grey.svg");
  background-position: top left;
  background-repeat: no-repeat;
  background-size: 45rem;
  position: relative;
  h2{
    color:#6d6d6d;
    font-size: 1.45rem;
    width: 23%;
    position: absolute;
    bottom:0;
    top:48px;
    left:20px;
    right:0;
    line-height:1.6;
  }
}

  .gradient-bck{
    background-image:linear-gradient(105deg, #f5faf9,#f4f9f7,#f4f9f8,#f4f9f9);
  }

  .surface-card{
    background-color: #fefefe;
    width: 60%;
    border-radius: 12px;
    box-shadow: 0px 4px 10px rgba(0, 0, 0, 0.03), 0px 0px 2px rgba(0, 0, 0, 0.06), 0px 2px 6px rgba(0, 0, 0, 0.12);
    padding:1.5rem;
    .surface-card-head{
      background-color: #fbfbfb;
      display: flex;
      justify-content: center;
      margin-bottom: 1rem;
      padding: 2rem;
      border-radius: 0.5rem;
    }
    .surface-card-body{
      text-align: center;
      h4{
        font-size: 1.75rem;
        font-weight: 600;
        padding:0.5rem;
        margin-bottom: 1rem;
      }
    }
    .surface-card-footer{
      display: flex;
      justify-content: center;
      padding:1rem;
    }
  }

.grid-container {
  padding: 20px;
}

.bottom-line{
  border-bottom: 1px solid #e1e1e1;
}

.coming-soon-container{
  background-size: cover;
  background-repeat: no-repeat;
  position: relative;
}

.vision-container{
  text-align: center;
}

//----------------------------------------------FORUM-------------------------------------------------------------------
.forum-info-card{
  background: #ffffff;
  color: #4b5563;
  -webkit-box-shadow: 0 2px 1px -1px rgba(0, 0, 0, 0.2), 0 1px 1px 0 rgba(0, 0, 0, 0.14), 0 1px 3px 0 rgba(0, 0, 0, 0.12);
  box-shadow: 0 2px 1px -1px rgba(0, 0, 0, 0.2), 0 1px 1px 0 rgba(0, 0, 0, 0.14), 0 1px 3px 0 rgba(0, 0, 0, 0.12);
  border-radius: 6px;
  padding: 3rem;
  .icon-text{
    text-align: center;
    h3{
      font-size:16px;
      color:$darkColor-900;
      font-weight: bold;
    }
    span{
      font-size:16px;
      color:$darkColor-900;
    }
  }
  p{
    padding:15px;
  }
  h2{
    padding:10px;
  }
}

  .description{
    color:grey;
  }

  .label-size{
    font-size:17px;
    margin-bottom: 8px;
  }

  .multiselect-dropdown-list{
    div{
      display: flex;
      flex-direction: row;
    }
  }


.cosi-info-card{
  background: #ffffff;
  color: #4b5563;
  -webkit-box-shadow: 0 2px 1px -1px rgba(0, 0, 0, 0.2), 0 1px 1px 0 rgba(0, 0, 0, 0.14), 0 1px 3px 0 rgba(0, 0, 0, 0.12);
  box-shadow: 0 2px 1px -1px rgba(0, 0, 0, 0.2), 0 1px 1px 0 rgba(0, 0, 0, 0.14), 0 1px 3px 0 rgba(0, 0, 0, 0.12);
  border-radius: 6px;
  padding: 3rem;
  .icon-text{
    text-align: center;
    h3{
      font-size:16px;
      color:$darkColor-900;
      font-weight: bold;
    }
    span{
      font-size:16px;
      color:$darkColor-900;
    }
  }
  h2{
    padding:10px;
  }
}

.cosi-card{
  background: #ffffff;
  color: #4b5563;
  -webkit-box-shadow: 0 2px 1px -1px rgba(0, 0, 0, 0.2), 0 1px 1px 0 rgba(0, 0, 0, 0.14), 0 1px 3px 0 rgba(0, 0, 0, 0.12);
  box-shadow: 0 2px 1px -1px rgba(0, 0, 0, 0.2), 0 1px 1px 0 rgba(0, 0, 0, 0.14), 0 1px 3px 0 rgba(0, 0, 0, 0.12);
  border-radius: 6px;
  padding: 2.25rem;
  //height: 105%;
  position: relative;
  h3{
    font-weight: 500;
    margin-bottom:15px;
  }
  ul{
    list-style-type: decimal;
    padding-left: 10px;
    font-size: 14px;
  }
  .footer-cosi{
    display: flex;
    justify-content: center;
    padding-top: 40px;
    .card-footer{
      position: absolute;
      bottom: 10%;
    }
  }
  .text-area{
    padding:10px;
  }
  .form-buttons{
    display: flex;
    justify-content: center;
  }
}

.content-reveal {
  display: none;
  //background: #ffffff;
  //color: #4b5563;
  //-webkit-box-shadow: 0 2px 1px -1px rgba(0, 0, 0, 0.2), 0 1px 1px 0 rgba(0, 0, 0, 0.14), 0 1px 3px 0 rgba(0, 0, 0, 0.12);
  //box-shadow: 0 2px 1px -1px rgba(0, 0, 0, 0.2), 0 1px 1px 0 rgba(0, 0, 0, 0.14), 0 1px 3px 0 rgba(0, 0, 0, 0.12);
  //border-radius: 6px;
  //padding: 2.25rem;
  //margin-top: 40px;
  h3{
    font-weight: 500;
    margin-bottom:15px;
  }
}

.cosi-form{
  display: none;
  //margin-top:20px;
}

  .cosi-container{
    background: #fdfdfd;
    color: #4b5563;
    -webkit-box-shadow: 0 2px 1px -1px #0003,0 1px 1px #00000024,0 1px 3px #0000001f;
    box-shadow: 0 2px 1px -1px #0003,0 1px 1px #00000024,0 1px 3px #0000001f;
    border-radius: 6px;
    margin:10px;
    h3{
      display: flex;
      justify-content: center;
      margin-top: 10px;
    }
    p{
      justify-content: center;
      display: flex;
    }
    .btn-padding{
      display: flex;
      justify-content: center;
    }
    .cosi-icon{
      font-size: 104px;
      color: $primeColor;
      padding-top: 20px;
    }
  }

 .cosi-resource{
   .cosi-resource-icon{
     font-size:7rem !important;
     color:#b5dad6;
   }
   h4{
     font-weight: 500;
   }
 }

//----------------------------------------------SIGNUP------------------------------------------------------------------
  .input-group-label{
    background: $primeColor;
    color:white;
    font-size:16px;
  }

  .padding-div{
    padding:10px
  }

  .password-panel{
    background: #fafafa;
    padding: 10px;
    margin-bottom: 20px;
    margin-top: 20px;
  }

  .password-panel-ul{
    padding: 10px;
    font-size: 0.9rem;
    border-radius: 12px;
    li{
      padding-left: 10px;
    }
  }

  .confirm-pass{
    color:$primeColor;
    font-size: 0.9rem;
    font-weight: bold;
  }

  .title-policy{
    font-weight: 700;
    padding-bottom: 10px;
    padding-left: 10px;
  }

//-------------------------------------------VIRTUAL TOUR PAGE----------------------------------------------------------

  .tabs-content.vertical {
    background: #fdfdfd !important;
    color: #4b5563;
    box-shadow: 0 2px 1px -1px #0003,0 1px 1px #00000024,0 1px 3px #0000001f;
    border-radius: 6px;
  }

  ul#example-tabs.vertical.tabs{
    background: #fdfdfd !important;
    color: #4b5563;
    box-shadow: 0 2px 1px -1px #0003,0 1px 1px #00000024,0 1px 3px #0000001f;
    border-radius: 6px;
    .tabs-title > a[aria-selected="true"] {
      background: $navBarColor;
      color: #4b5563;
      font-size: 1.125rem;
    }
    .tabs-title > a {
      font-size:  1.10rem;
      color: #4b5563;
      font-weight: 450;
    }
  }
//-----------------------------------------------FONT STYLES------------------------------------------------------------

h2{
  color: $primeColor;
  font-weight: 600;
  font-size: 2.3rem;
  padding-bottom:10px;
}

.header-style-blue{
  color: $primeColor;
  font-weight: 600;
}

.header-style{
  color: #253b3c;
  font-size: 1.75rem;
  font-weight: 700;
  margin-bottom: 30px;
}

.header-style-medium{
  color: #253b3c;
  font-size: 1.275rem;
  font-weight: 600;
  margin-bottom: 15px;
}

.header-style-small{
  color: #253b3c;
  font-size: 1.10rem;
  font-weight: 600;
  margin-bottom: 15px;
}

.page-title-blue{
  color: #85bfba;
  font-weight: 600;
  font-size: 2rem;
  padding-bottom: 0.5rem;
}

.paragraph-size{
  font-size:14px;
}

.text-xs{
  font-size: .75rem;
  font-style: italic;
}

.about-us-heading{
  font-size:1.65rem;
}

//---------------------------------------------------IMG IFRAME---------------------------------------------------------
.top-image-wrapper{
  position: relative;
  display:inline-block;
  .top-image .picture{
    background-repeat: no-repeat;
    background-size: cover;
    max-width:100%;
    position: relative;
  }
  .top-image-line{
    position: absolute;
    bottom:-10%;
    background-image: url("https://id-ni.eco/assets/images/svg/line-topimage.svg");
    //width: 100.1%;
    //height: 114%;
    background-repeat: no-repeat;
    background-position: bottom;
    background-size: cover;
    right: -1%;
    left: 0;
    top: 12%;
  }
}

  .menu-logo{
    height: 70px;
    margin-left: 0.5rem;
    margin-right: 0.5rem;
  }

  .page-img{
    max-width:100%;
  }

  .vision-img{
    max-width: 90%;
  }

  .page-logo{
    display: block;
    max-width: 340px;
    max-height: 250px;
    width: auto;
    height: auto;
    padding: 10px;
  }

  .partner-logo{
    display: block;
    max-width: 250px;
    max-height: 100px;
    width: auto;
    height: auto;
  }

  .council-logo{
      display: block;
      max-width: 250px;
      max-height: 120px;
      width: auto;
      height: auto;
      padding:10px;
  }

  .council-header-logo{
    display: block;
    //max-width: 200px;
    max-height: 119px;
    width: auto;
    height: 100px;
    padding:18px;
  }

  iframe{
    border: none;
    width: 100%;
    aspect-ratio: 16/9;
  }

  .iframe-container{
    background: #fdfdfd !important;
    color: #4b5563;
    box-shadow: 0 2px 1px -1px #0003,0 1px 1px #00000024,0 1px 3px #0000001f;
    border-radius: 6px;
  }

//----------------------------------------------------BUTTONS---------------------------------------------------------

  .btn-padding{
    padding-top:1rem;
    padding-bottom:1rem;
  }

  .close-btn{
    display: flex;
    justify-content: end;
    i{
      font-size: 20px;
      color: gray;
    }
  }

.close-join-panel{
  display: flex;
  justify-content: end;
  i{
    font-size: 20px;
    color: gray;
  }
}

.btn-cosi-login{
  background-color: #b5dad6 !important;
  border:#b5dad6;
  color:#2e5757;
  font-weight: 700;
  border-radius: 2rem;
  padding: 12px;
  &:hover{
    border-radius: 2rem !important;
    background-color:$darkColor !important;
    color:white !important;
    font-weight: 700 !important;
  }
}

.btn-cosi-register{
  background-color: #458785 !important;
  border:#458785;
  color:white;
  font-weight: 700;
  border-radius: 2rem;
  padding: 12px;
  &:hover{
    border-radius: 2rem !important;
    background-color:$primeColor !important;
    color:#2e5757 !important;
    font-weight: 700 !important;
  }
}

  .btn-style{
    background-color: $primeColor !important;
    border:$primeColor;
    color:#283938;
    font-weight: 700;
    border-radius: 2rem;
    padding: 1em 1em;
    &:hover{
      border-radius: 2rem !important;
      background-color:$darkColor !important;
      color:white !important;
      font-weight: 700 !important;
    }
  }

  .btn-gradient {
    background-image: linear-gradient(#458785,#356c6b);
    border: 1px solid #458785;
    font-weight: 700;
    color:white;
    border-radius: 2rem;
    padding: 1em 1em;
    margin-right: 0.5rem;
    &:hover {
      background-image: linear-gradient(#356c6b, #458785);
      border: 1px solid #458785;
      border-radius: 2rem;
      font-weight: 700;
      color:white;
    }
  }

  .btn-outline{
    background-color: #f4f9f8;
    border:1px solid $darkColor-700;
    color:$darkColor-700;
    font-weight: 700;
    box-shadow: 0 3px 1px -2px #0003,0 2px 2px #00000024,0 1px 5px #0000001f;
    border-radius: 6px;
    transition: background-color .2s,color .2s,border-color .2s,box-shadow .2s;
    &:hover{
      background-color:$darkColor;
      border:1px solid $lightColor-50;
      color:#f4f9f8;
      font-weight: 700;
      box-shadow: 0 3px 1px -2px #0003,0 2px 2px #00000024,0 1px 5px #0000001f;
      border-radius: 6px;
      transition: background-color .2s,color .2s,border-color .2s,box-shadow .2s;
    }
  }

  .btn-outline-round{
    background-color: #f4f9f8;
    border:1px solid $darkColor-700;
    color:$darkColor-700;
    font-weight: 700;
    box-shadow: 0 3px 1px -2px #0003,0 2px 2px #00000024,0 1px 5px #0000001f;
    transition: background-color .2s,color .2s,border-color .2s,box-shadow .2s;
    border-radius: 50%;
    &:hover{
      background-color:$darkColor;
      border:1px solid $lightColor-50;
      color:#f4f9f8;
      font-weight: 700;
      box-shadow: 0 3px 1px -2px #0003,0 2px 2px #00000024,0 1px 5px #0000001f;
      transition: background-color .2s,color .2s,border-color .2s,box-shadow .2s;
      border-radius: 50%;
    }
  }

  .btn-files{
    background-color: white;
    border:1px solid #b5dad6;
    color:$darkColor-700;
    font-weight: 700;
    -webkit-box-shadow: 0 2px 1px -1px #0003,0 1px 1px #00000024,0 1px 3px #0000001f;
    box-shadow: 0 2px 1px -1px #0003,0 1px 1px #00000024,0 1px 3px #0000001f;
    border-radius: 6px;
    transition: background-color .2s,color .2s,border-color .2s,box-shadow .2s;
    &:hover{
      background-color:$darkColor;
      border:1px solid #b5dad6;
      color:white;
      font-weight: 700;
      border-radius: 6px;
    }
  }

  .qq-upload-button{
    @extend .button;
    @extend .btn-style;
    padding: 15px !important;
    color: #283938 !important;
    &:hover{
      border-radius: 2rem !important;
      background-color:$darkColor !important;
      color:white !important;
      font-weight: 700 !important;
    }
  }

.sign-up-btn {
  background-image: linear-gradient(#458785,#356c6b);
  border: 1px solid #458785;
  font-weight: 700;
  color:white;
  border-radius: 2rem;
  padding: 1em 1em;
  margin-right: 0.5rem;
  width: 93px;
  font-size: 13px;
  &:hover {
    background-image: linear-gradient(#356c6b, #458785);
    border: 1px solid #458785;
    border-radius: 2rem;
    font-weight: 700;
    color:white;
  }
}

.login-btn{
  background-color: #f4f9f8;
  border:1px solid $darkColor-700;
  color:$darkColor-700;
  font-weight: 700;
  box-shadow: 0 3px 1px -2px #0003,0 2px 2px #00000024,0 1px 5px #0000001f;
  border-radius: 6px;
  transition: background-color .2s,color .2s,border-color .2s,box-shadow .2s;
  width: 80px;
  font-size: 13px;
  &:hover{
    background-color:$darkColor;
    border:1px solid $lightColor-50;
    color:#f4f9f8;
    font-weight: 700;
    box-shadow: 0 3px 1px -2px #0003,0 2px 2px #00000024,0 1px 5px #0000001f;
    border-radius: 6px;
    transition: background-color .2s,color .2s,border-color .2s,box-shadow .2s;
  }
}

.pet-btn{
  background-color: $primeColor !important;
  border:$primeColor;
  color:#283938;
  font-weight: 700;
  border-radius: 2rem;
  padding: 1em 1em;
  width: 106px;
  font-size: 13px;
  &:hover{
    border-radius: 2rem !important;
    background-color:$darkColor !important;
    color:white !important;
    font-weight: 700 !important;
  }
}


  //--------------------------------------------------MENU--------------------------------------------------------------

.sidebar{
  display: none;
  width: 100%;
  background: white;
  padding: 2.3rem;
  .sidenav{
    position: fixed;
    transform: translateY(-150%);
    transition: transform 0.2s;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    z-index: 99;
    //background: #fefefe;
    background: #fdfdfd;
    overflow-y:scroll;
    list-style: none;
    padding: 2rem;
    width: 350px;
    box-shadow: 0 2px 1px -1px rgba(0, 0, 0, 0.2), 0 1px 1px 0 rgba(0, 0, 0, 0.14), 0 1px 3px 0 rgba(0, 0, 0, 0.12);
    color:#334155;
    .menu{
      display: flex;
      flex-direction: column;
      .sidenav-btn{
        display: flex;
        flex-direction: column;
        margin-top: 20px;
      }
      .burger-logo{
        background: #f4f9f8;
        padding:10px;
        border-radius: 15px;
      }
      .is-active > a {
        background: #fafafa;
        color: #fefefe;
      }
      ul.sidenav-dropdown{
        display: flex;
        flex-direction: column;
        text-decoration: none;
        list-style: none;
        margin-top: 35px;
        a{
          text-decoration: none;
          color: #334155;
        }
      }
    }
    .dropdown.menu > li.is-dropdown-submenu-parent > a::after {
      display: block;
      width: 0;
      height: 0;
      border-style: solid;
      border-width: 6px;
      border-bottom-width: 6px;
      content: '';
      border-bottom-width: 0;
      border-color: $primeColor transparent transparent;
      right: 5px;
      left: auto;
      margin-top: -3px;
      &:hover{
        background-color: $primeColor;
      }
    }
    .dropdown.menu > li.opens-right > .is-dropdown-submenu {
      top: 100%;
      right: auto;
      left: 2%;
      background: #f1f1f1;
      border: 1px solid white;
      border-radius: 10px;
      position: absolute;
      padding: 15px;
      width: 90%;
    }
    li.is-submenu-items.is-dropdown-submenu-item{
      &:hover{
        background-color: #f4f4f4;
      }
    }
  }
  .hamburger {
    position: absolute;
    z-index: 100;
    top: 1rem;
    right: 1rem;
    padding: 4px;
    border: 1px solid $primeColor;
    background: $primeColor;
    cursor: pointer;
    border-radius: 10px;
  }
  .closeIcon {
    display: none;
    font-size: 30px;
    color:$darkColor;
  }

  .menuIcon{
    display: block;
    font-size: 30px;
    color:$darkColor;
  }

  .showMenu {
    transform: translateY(0);
  }
}

.navbar{
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    width: 100%;
  }
  .title-bar {
    //background: $primeColor;
    background: $navBarColor;
    color: #4b5563;
    display: flex;
    justify-content: end;
    padding:15px !important;
    span{
      font-size:40px !important;
      color:$primeColor;
    }
  }

  .top-bar {
    background-color: $navBarColor;
    padding:5px;

    input{
      //max-width: 90px;
    }

    a {
      color: #4b5563;
      font-weight: 410;
      &:hover {
        background: $primeColor;
        border-radius: 10px;
      }
    }
    .a-style-none{
      color:white;
      font-weight:500;
      line-height: 0;
      padding: 0px;
      &:hover {
        background: none;
        border-radius: 0px;
      }
    }

    ul.dropdown.menu {
      background-color: $navBarColor !important;
    }

    .dropdown.menu > li.is-dropdown-submenu-parent > a::after {
      border-color: $primeColor transparent transparent;
    }

    .dropdown.menu > li.is-active > a {
      background: transparent;
      color: #4b5563;
    }

    .menu .is-active > a {
      background: $navBarColor;
      color: #4b5563;
    }

    .dropdown.menu > li.opens-left > .is-dropdown-submenu {
      top: 100%;
      right: 0;
      left: auto;
      background-color: white;
      padding: .8rem;
      width: 16.5rem;
    }
    .display-logo{
      display:none;
    }
    .is-dropdown-submenu {
      position: absolute;
      z-index: 1;
      min-width: 223px;
      border: none;
      //background: $primeColor;
    }
  }

  .nav-btn{
    display: flex;
    align-items: end;
  }

.search-panel{
    display: none;
    background: #f1f1f1;
    padding: 10px;
    border-radius: 20px;
    position: absolute;
    top: 64px;
    right: 307px;
    z-index: 1;
    height: 60px;
    //width: 11.3%;
    box-shadow: 0px 4px 10px rgba(0, 0, 0, 0.1);
}
//----------------------------------------------------SEARCH PAGE-----------------------------------------------------
.simplesearch-results-list{
  .simplesearch-result{
    color:$primeColor;
    font-weight: 700;
    h3{
      padding-top:10px;
      font-size:1.7rem;
      a{
        color:$darkColor-900;
      }
    }
  }
}

.simplesearch-result-pages{
  padding-right:15px;
}

div.simplesearch-paging{
  padding-top:20px;
  padding-bottom:20px;
}

span.simplesearch-page.simplesearch-current-page{
  color:$primeColor;
}

.simplesearch-page{
  a{
    color:$darkColor-900;
  }
}

  .search-box{
    position: relative;
  }

  .search-input{
    border-radius: 20px;
    width: 250px;
    //display:none;
  }

  .search-btn {
    //background-color: $primeColor;
    background-image: linear-gradient(#458785,#356c6b);
    padding: 12px;
    position: absolute;
    border-radius: 20px;
    color: white;
    right: 8%;
    top: 0;

    &:hover {
      background-color: $primeColor;
    }
  }


.show-search-bar {
  position: absolute;
  top: 20px;
  right: 19rem;
}

.search-btn-navbar {
  background-color: $primeColor;
  padding: 12px;
  position: absolute;
  border-radius: 20px;
  color: white;
  right: 8%;
  top: 0;

  &:hover {
    background-color: $primeColor;
  }
}

//------------------------------------------------------FOOTER--------------------------------------------------------
.footer-container{
  //background-color: #f8f8f8;
  background-color:#fcfcfc;
  background-image: url("https://id-ni.eco/assets/images/svg/curve-bottom.svg");
  background-position: top left;
  background-repeat: no-repeat;
  background-size: 30rem;
  padding:20px;
  .footer-logo{
    display: block;
    max-width: 170px;
    max-height: 90px;
    width: auto;
    height: auto;
  }
  .footer-social-icon{
    font-size: 3em;
    padding: 10px 10px;
    color:#356c6b;
    &:hover{
      color:#b5dad6;
      transform:scale(1.5);
    }
  }
 a{
   color: #4b5563;
   font-weight: 500;
 }
  .menu .active > a {
    background: $primeColor;
    color: #4b5563;
    font-weight: 500;
    border-radius: 10px;
  }
  .footer-input{
    border-radius: 15px;
    padding: 20px;
    margin-top: 10px;
  }
  .copyright{
    font-weight: 300;
    color:#e2e2e2;
    margin-top:10px;
  }
}


//----------------------------------------------------COUNCIL PAGE------------------------------------------------------
.factsheet{
  background-color:white;
  background-image: url("https://id-ni.eco/assets/images/svg/curve-grey.svg");
  background-position: top left;
  background-repeat: no-repeat;
  background-size: 50vw;
  margin-top:1.5rem;
  .project-content{
    padding-bottom: 15px;
    font-size: .865rem;
  }
  .ni-council-footer{
    background-color:#fbfbfb;
    padding-bottom:30px;
  }
  .ni-council-footer-logo{
    display: flex;
    justify-content: space-evenly;
  }
  .ni-council-header{
    width:29%;
    margin-bottom:20px;
  }
  .factsheet-icon{
    font-size:7rem !important;
    color:#b5dad6;
  }
  .file-info-p{
    padding-top:10px;
  }
  .contact-icons{
    font-size:3rem !important;
    color:$primeColor;
    margin-bottom:10px;
  }
  .contact-container{
    max-width: 95rem;
    margin-bottom: 40px;
    margin-left: 22%;
  }
  .title-wrapper{
    margin-top:30px;
  }
  .statement-size{
    font-size:.92rem;
  }
  .email-size{
    font-size: .7rem;
  }
}


.a-link-style{
  color:$primeColor;
  &:hover{
    color:$darkColor-700;
  }
}

//------------------------------------------------EVENTS----------------------------------------------------------------
  .news-card{
    position: relative;
    margin-bottom:35px;
    text-align: center;
    height:100%;
    background: #fdfdfd !important;
    color: #4b5563;
    -webkit-box-shadow: 0 2px 1px -1px #0003,0 1px 1px #00000024,0 1px 3px #0000001f;
    box-shadow: 0 2px 1px -1px #0003,0 1px 1px #00000024,0 1px 3px #0000001f;
    border-radius: 6px;
    .news-line{
      border-bottom: 1px inset white;
    }
    .card-footer-position{
      display: flex;
      justify-content: center;
      padding-top: 40px;
      .card-footer{
        position:absolute;
        bottom:25px;
        height:3%;
        padding-bottom:60px;
      }
    }
    .card-body{
      padding:45px;
    }
    .card-head{
      display: block;
      position: relative;
      &:after{
        content: " ";
        background-image: url("https://id-ni.eco/assets/images/svg/curve-top-grey.svg");
        background-position: bottom;
        background-size: contain;
        background-repeat: no-repeat;
        position: absolute;
        top:0;
        bottom:0;
        left:7px;
        right:0;
      }
      .news-img{
        max-width:100%;
        margin: auto;
      }
      span{
        position:absolute;
        top:83%;
        right: 25px;
        z-index:1;
      }
    }
  }

.extra-padd{
  padding: 3% !important;
}

//---------------------------------------------------TECHNOLOGY PAGE----------------------------------------------------

.tech-container{
  background: #fdfdfd;
  color: #4b5563;
  -webkit-box-shadow: 0 2px 1px -1px #0003,0 1px 1px #00000024,0 1px 3px #0000001f;
  box-shadow: 0 2px 1px -1px #0003,0 1px 1px #00000024,0 1px 3px #0000001f;
  border-radius: 6px;
}

.disclaimer-font{
  font-size:.75rem;
  font-style:italic;
  margin-left:15px;
}

  .technology-sidebar{
    display: flex;
    flex-direction: row;
    margin-bottom:15px;
    h5{
      padding-left:15px;
    }
    span{
      font-size: 23px;
      color: $primeColor;
      font-weight: 700;
    }
  }

  .search-bar{
    display: flex;
    flex-direction: row;
    button{
      background-color: #85bfba !important;
      border: #85bfba;
      color: #283938;
      font-weight: 700;
      height: 39px;
    }
    span{
      font-size: 19px;
    }
  }

  .sidenav-title{
    padding-bottom: 20px;
    font-weight: 450;
    padding-top: 25px;
  }

//-------------------------------------------------------LANDING PAGE---------------------------------------------------
.orbit {
  position: relative;
  overflow: hidden;

  .slider-video {
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    object-fit: cover;
    z-index: -1;
  }
  .content{
    padding-left: 2rem;
    color: white;
    display:flex;
    flex-direction: column;
    justify-content: center;
    position: absolute;
    top: 50%;
    transform: translateY(-50%);
    h1{
      font-size: 2.27rem;
      //width: 55%;
      width:45%;
      font-weight: 600;
    }
    p{
      font-size: 1.27rem;
      padding-top:2rem;
      line-height: 1.3;
      //width: 60%;
      width:45%;
    }
  }

  .orbit-container {
    position: relative;
    height: 100vh;
  }

  .orbit-slide {
    width: 101%;
    position: absolute;
    height: 90vh;
  }

  .bg-arrow{
    position:absolute;
    height: 90vh;
    width:43vw;
    background-image: url("https://id-ni.eco/assets/images/svg/arrow.svg");
    background-repeat: no-repeat;
    background-size: 94%;
    //right: 63.5%;
    right:67%;
  }
  .orbit-bullets{
    bottom:4rem;
  }
}




.hero{
    background-size: cover;
    background-repeat: no-repeat;
    height: 90vh;
    position: relative;
    color:white;
    z-index: -1;
  .bg-arrow{
    position:absolute;
    height: 90vh;
    width:42vw;
    background-image: url("https://id-ni.eco/assets/images/svg/arrow.svg");
    background-repeat: no-repeat;
    background-size: 100%;
  }
  .content{
    padding-left: 3rem;
    color: white;
    display:flex;
    flex-direction: column;
    justify-content: center;
    position: absolute;
    top: 50%;
    transform: translateY(-50%);
    h1{
      font-size: 2.5rem;
      width: 55%;
      font-weight: 600;
    }
    p{
      font-size: 1.5rem;
      padding-top:2rem;
      line-height: 1.3;
      width: 60%;
    }
  }
}

.hero-wrapper{
  position: relative;
  img{
    background-size: cover;
    background-repeat: no-repeat;

  }
  .hero-arrow{
    position: absolute;
    height: 172vh;
    width: 49.1vw;
    background-image: url("https://id-ni.eco/assets/images/svg/arrow.svg");
    background-repeat: no-repeat;
    top: 0;
  }
  .content{
    padding-left: 3rem;
    color: white;
    display: flex;
    flex-direction: column;
    justify-content: center;
    position: absolute;
    top: 50%;
    transform: translateY(-50%);
    h1{
      font-size: 2.5rem;
      width: 55%;
      font-weight: 600;
    }
    p{
      font-size: 1.5rem;
      padding-top:10px;
      width: 60%;
    }
  }
}

.card-info-video{
  height:100%;
  border: 1px solid #e6e6e6;
  border-radius: 20px;
  position: relative;
  box-shadow: 0 2px 1px -1px rgba(0, 0, 0, 0.2), 0 1px 1px 0 rgba(0, 0, 0, 0.14), 0 1px 3px 0 rgba(0, 0, 0, 0.12);
  .card-img{
    position: relative;
    width: 100%;
    height: 308px;
    object-fit: cover;
  }
  .card-section{
    text-align: center;
    background: #fcfcfc;
    h4{
      font-size: 1.4rem;
    }
  }
  .overlay-img{
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 308px;
    background-color: rgba(0, 0, 0, 0.3);
    z-index: 1;
  }
  .play-video-btn{
    color: white;
    position: absolute;
    top: 28%;
    left: 50%;
    transform: translate(-50%, -50%);
    z-index: 2;
  }
}

.card-info-video-mini{
  height:100%;
  border: 1px solid #e6e6e6;
  border-radius: 20px;
  position: relative;
  box-shadow: 0 2px 1px -1px rgba(0, 0, 0, 0.2), 0 1px 1px 0 rgba(0, 0, 0, 0.14), 0 1px 3px 0 rgba(0, 0, 0, 0.12);
  .card-img{
    position: relative;
    width: 100%;
    height: 308px;
    object-fit: cover;
  }
  .card-section{
    text-align: center;
    background: #fcfcfc;
    h4{
      font-size: 1.4rem;
    }
  }
  .overlay-img{
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 308px;
    background-color: rgba(0, 0, 0, 0.3);
    z-index: 1;
  }
  .play-video-btn{
    color: white;
    position: absolute;
    top: 28%;
    left: 50%;
    transform: translate(-50%, -50%);
    z-index: 2;
  }
}

.info-video{
  width: 100%;
  aspect-ratio: 16/9;
  padding:35px;
}



//---------------------------------------------------CAROUSEL PARTNER---------------------------------------------------
.navigation-wrapper {
  position: relative;
  .dots {
    display: flex;
    padding: 10px 0;
    margin-top:30px;
    justify-content: center;
  }
  .dot {
    border: none;
    width: 14px;
    height: 14px;
    background-color: #9ca3af;
    border-radius: 50%;
    margin: 0 5px;
    padding: 5px;
    cursor: pointer;
    &:focus {
      outline: none;
    }
  }
  .dot--active {
    background: $primeColor;
  }
  .arrow {
    width: 30px;
    height: 30px;
    position: absolute;
    top: 50%;
    transform: translateY(-50%);
    -webkit-transform: translateY(-50%);
    fill: #fff;
    cursor: pointer;
    background-repeat: no-repeat;
  }
  .arrow-left {
    right:105%;
    background-image: url("https://id-ni.eco/assets/images/svg/arrow-left.svg");
  }
  .arrow-right {
    left: auto;
    right: 5px;
    background-image: url("https://id-ni.eco/assets/images/svg/arrow-right.svg");
  }
}

.partner-card{
  position: relative;
  text-align: center;
  height:100%;
  display: flex;
  flex-direction: column;
  .partner-card-head{
    display: flex;
    justify-content: center;
    align-items: center;
  }
  .partner-card-body{
    padding-top:20px;
    h3{
      margin-bottom:1.5rem;
      font-size:1.25rem;
      text-align: center;
      font-weight: 600;
    }
    p{
      font-size: .875rem;
      padding:1rem;
    }
  }
}

//---------------------------------------------------ACCORDION SECTION--------------------------------------------------
:last-child:not(.is-active) > .accordion-title {
  border-bottom: 0px solid #e6e6e6;
  border-radius: 0 0 0 0;
}

:last-child > .accordion-content:last-child {
  border-bottom: 0px solid #e6e6e6;
}

.accordion-menu .is-accordion-submenu-parent:not(.has-submenu-toggle) > a::after {
  border-color: $primeColor transparent transparent;
  position: absolute;
  top: 50%;
  right: 2rem;
}

.accordion-menu {
  a {
    padding: 20px !important;
  }
  .menu.nested {
    margin-right: 0;
    margin-left: 0;
  }
  .accordion-header {
    border-radius: 8px;
    cursor: pointer;
    background: white;
    -webkit-box-shadow: 0 2px 1px -1px #0003, 0 1px 1px #00000024, 0 1px 3px #0000001f;
    box-shadow: 0 2px 1px -1px #0003, 0 1px 1px #00000024, 0 1px 3px #0000001f;
    font-weight: 500;
    color: #4b5563;
  }
  .submenu-accordion{
    border-bottom: 1px solid #F1F1F1;
    border-left: 1px solid #F1F1F1;
    border-right: 1px solid #F1F1F1;
    padding: 20px;
    border-radius: 6px;

  }
}


.faqs-description{
  display: flex;
  justify-content: center;
  padding:26px;
  text-align: center;
}


//---------------------------------------------------MEDIA QUERY--------------------------------------------------------

  @media #{$small-only} {
    .cosi-info-card{
      h2{
        font-size:2rem;
      }
    }
    .ni-council-footer-logo{
      flex-direction: column;
    }
    .factsheet{
      background-size: 306vw;
      .ni-council-header{
        width: 85%;
      }
    }
    .top-bar{
      display: none;
    }
    .sidebar{
      display: block;
    }
    .vision-img{
      max-width: 60%;
    }
    .orbit{
      .orbit-slide{
        background: rgba(0, 0, 0, 0.5);
      }
      .orbit-bullets{
        bottom:3rem;
      }
      .bg-arrow {
        background-image: none;
      }
      .content {
        width: 120%;
        padding-left:1rem;
        h1 {
          font-size: 1.7rem;
          width: 80%;
        }
        p {
          font-size: 1rem;
          width: 80%;
        }
      }
    }
    .navigation-wrapper{
      .arrow-left {
        right: 95%;
      }
      .arrow-right{
        left:98%;
      }
    }
    .accordion-menu .is-accordion-submenu-parent:not(.has-submenu-toggle) > a::after {
      top: 73%;
      right: 14px;
    }
  }

  @media #{$medium-only} {
    .top-bar{
      display: none;
    }
    .sidebar{
      display: block;
    }

    .vision-container{
      display: flex;
      justify-content: center;
    }
    .factsheet{
      background-size: 120vw;
      .ni-council-header {
        width: 80%;
        margin-bottom: 10%;
      }
    }
    .council-header-logo {
      max-height: 100px;
    }
    .vision-img{
      max-width: 67%;
    }
    .orbit{
      .orbit-bullets{
        bottom:3rem;
      }
      .orbit-slide{
        background: rgba(0, 0, 0, 0.5);
      }
      .bg-arrow{
        background-image:none;
      }
      .content{
        width:80%;
        padding-left: 1rem;
        p{
          font-size:0.85rem;
          width:58%;
        }
        h1{
          font-size:1.2rem;
          width:58%;
        }
      }
    }
    .navigation-wrapper .arrow-left{
        right: 98%;
    }
    .navigation-wrapper .arrow-right{
      left: 99%;
    }
    .accordion-menu .is-accordion-submenu-parent:not(.has-submenu-toggle) > a::after {
      top: 63%;
      right: 14px;
    }
    .footer-social-icons{
      display: flex;
      flex-direction: column;
    }
  }

  @media #{$large-only}{
    .top-bar{
      display: none;
    }
    .sidebar{
      display: block;
    }
  }

//@media screen and (max-width: 1372px) and (min-width: 1200px){
//  .top-bar{
//    display: none;
//  }
//  .sidebar{
//    display: block;
//  }
//}


@media screen and (max-width: 1200px) and (min-width: 1024px){
  .orbit{
    .bg-arrow{
      width:77vw;
    }
  }
  .factsheet {
    background-size: 100vw;
    .title-wrapper{
      margin-top:7rem;
    }
    .ni-council-header {
      width: 52%;
    }
  }
}

@media screen and (max-width: 1440px) and (min-width: 1200px){
  //.top-bar{
  //  display: block;
  //}
  //.sidebar{
  //  display: none;
  //}
  .orbit {
    .bg-arrow {
      width:47vw;
    }
    .content {
      p {
        font-size: 1.2rem;
        width: 70%;
      }
      h1 {
        font-size: 1.8rem;
        width: 60%;
      }
    }
  }
  .factsheet {
    background-size: 88vw;
    .title-wrapper{
      margin-top:7rem;
    }
    .ni-council-header {
      width: 44%;
    }
  }
}
//@media screen and (max-width: 1500px) and (min-width: 1400px){
//  .top-bar{
//    display: none;
//  }
//  .sidebar{
//    display: block;
//  }
//}

@media screen and (max-width: 1710px) and (min-width: 1440px){
  .orbit{
    .bg-arrow{
      width:53vw;
      //width:45vw;
      background-size: 100%;
      //right:61%;
      right:65%;
    }
    .content{
      h1{
        font-size:2rem;
      }
      p{
        font-size:1.2rem;
      }
    }
  }
  .factsheet {
    background-size: 65vw;
    .title-wrapper{
      margin-top:5rem;
    }
    .ni-council-header {
      width: 38%;
    }
  }
}

@media screen and (max-width: 1280px) and (min-width: 800px){
  .navigation-wrapper .arrow-left{
    right: 99%;
  }
  .orbit {
    .bg-arrow {
      width:50vw;
    }
  }
  .top-bar{
    display: none;
  }
  .sidebar{
    display: block;
  }
}

@media screen and (max-width: 1024px) and (min-width: 768px){
  .orbit {
    .bg-arrow {
      //width:61vw;
      //width: 120vw;
      width:68vw !important;
      right: 43% !important;

    }
    .content{
      //width:100%;
      width:57%;
      h1{
        font-size: 1.5rem;
        width: 75%;
      }
      p{
        font-size: 1rem;
        width: 80%;
      }
    }
  }
}

@media screen and (max-width: 1366px) and (min-width: 1024px){
  .orbit {
    .bg-arrow {
      width:60vw;
    }
  }
  .btn-cosi-register{
    padding:11px;
  }
  .btn-cosi-login{
    padding:11px;
  }
}

@media screen and (max-width: 1481px) and (min-width: 1024px){
  .orbit {
    .bg-arrow {
      //width: 60vw;
      width: 59vw;
      right: 55%;
    }
  }

  .menu-logo{
    margin-left:0px;
    margin-right:0px;
  }
}

@media screen and (min-width: 1024px) and (max-width: 1024px) and (min-height: 600px) and (max-height: 600px) {
  .orbit{
    .bg-arrow{
      width: 60vw !important;
      right: 54% !important;
    }
    .content{
      width:75%;
    }
  }
}

@media screen and (min-width: 1280px) and (max-width: 1280px) and (min-height: 800px) and (max-height: 800px) {
  .orbit{
    .bg-arrow{
      width:55vw;
    }
  }
}


@media screen and (min-width: 1366px) and (max-width: 1366px) and (min-height: 1024px) and (max-height: 1024px) {
   .orbit{
     .bg-arrow{
       width:66vw;
       right:51%;
     }
   }
}

@media screen and (min-width: 1920px) and (max-width: 1920px) and (min-height: 1200px) and (max-height: 1200px) {
  .orbit{
    .bg-arrow{
      width:55vw;
      right:60.5%;
    }
  }
}

@media screen and (min-width: 1920px) and (max-width: 1920px) and (min-height: 1080px) and (max-height: 1080px) {
  .orbit{
    .bg-arrow{
      width:50vw;
      right:61.5%;
    }
  }
}

